import React from 'react'
import { graphql } from "gatsby"
import Layout from "../../components/layout"

export const query = graphql`
  query AboutQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`

const DebugPage = ({data}) => (
  <Layout>
    <h1>Hiya</h1>
    <h4>debug:</h4>
    <pre>{JSON.stringify(data, null, 2 )}</pre>
  </Layout>
)

export default DebugPage
